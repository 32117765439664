@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  border: 2px;
  border-color: grey;
  border-style: solid;
}

.gradient,
.active {
  border: 2px;
  border-color: grey;
  border-style: solid;
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#d9d9d9] to-[#6a6a6a] font-bold font-primary;
  }
  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
}
